// ------------------------------------------------------------------------------
// KIDIWI Digital property
// Management of the MiniWeb Template library
// This template management is helping Kidiwi Services to control in a statis manner
// the authorisation to input data from the WebAdmin page of the Service Provider
// If the attribute is true, it means that the SP can input the data, else he will not.
// This is static data, that can be changed from compilation only.
// ------------------------------------------------------------------------------

import { Logger } from 'aws-amplify'
import { TEMPLATE_MINIWEB_LIST } from '@/services/miniweb/resolver'

const logger = new Logger('Resource list')

export const MINIWEB_BASIC_COMPANY_TEMPLATE = {
  id: 1,
  template: TEMPLATE_MINIWEB_LIST[0], // 'Basic',
  identity: {
    global: true,
    companyName: true,
    siret: true,
    address: true,
    logo: true,
  },
  contact: {
    phone: true,
    email: true,
  },
  showroom: {
    priceRange: true,
    backgroundPicture: false,
    pictures: false,
  },
  services: {
    backgroundPicture: false,
  },
  experiences: {
  },
  diploma: {
  },
  testimonies: {
  },
  specialties: {
  },

  activity: {
  },
  verbatim: {
  },
}

export const MINIWEB_ELEGANT_COMPANY_TEMPLATE = {
  id: 2,
  template: TEMPLATE_MINIWEB_LIST[1], // 'Elegant',
  identity: {
    global: true,
    companyName: true,
    siret: true,
    address: true,
    logo: true,
  },
  contact: {
    phone: true,
    email: true,
  },
  showroom: {
    priceRange: true,
    backgroundPicture: true,
    pictures: true,
  },
  services: {
    backgroundPicture: true,
  },
}

export class MiniWebTemplateManager {
  properties = {}
  // The default template is the basic one
  constructor () {
    logger.info('MiniWeb Template Manager.')
    this.properties = Object.assign({}, MINIWEB_BASIC_COMPANY_TEMPLATE)
  }

  templateList () {
    return [MINIWEB_BASIC_COMPANY_TEMPLATE.template, MINIWEB_ELEGANT_COMPANY_TEMPLATE.template]
  }

  // Select the template using the id
  selectByID (templateLayoutId) {
    if (MINIWEB_BASIC_COMPANY_TEMPLATE.template.id === templateLayoutId) {
        this.properties = Object.assign({}, MINIWEB_BASIC_COMPANY_TEMPLATE)
        return true
    }
    if (MINIWEB_ELEGANT_COMPANY_TEMPLATE.template.id === templateLayoutId) {
        this.properties = Object.assign({}, MINIWEB_ELEGANT_COMPANY_TEMPLATE)
        return true
    }
    return false // The template switch did not happen
  }

  // Get the template properties
  get () {
    return this.properties
  }
}
